import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react"
import {
  Manager,
  Reference,
  Popper,
  ReferenceChildrenProps,
} from "react-popper"
import { Placement } from "popper.js"
import { lightTheme, darkTheme } from "../../../services/theme"
import { useStyle } from "../../../hooks/use-style"
import React from "react"

export type Props = {
  visible: boolean
  placement: Placement
  element: (props: ReferenceChildrenProps) => ReactNode
  onClickOutside?: (event: MouseEvent) => void
}

const Dropdown: FunctionComponent<Props> = ({
  visible: isVisible,
  placement,
  element,
  onClickOutside,
  children,
}) => {
  const clickBoundary = useRef<HTMLDivElement>(null)
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        onClickOutside &&
        clickBoundary.current &&
        !clickBoundary.current.contains(event.target as Node)
      ) {
        window.requestAnimationFrame(() => onClickOutside(event))
      }
    },
    [onClickOutside]
  )

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor: theme === "light" ? "transparent" : darkTheme.color.border,
    shadowColor:
      theme === "light" ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0.5)",
  }))

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [handleClickOutside])

  return (
    <Manager>
      <Reference>{element}</Reference>

      <Popper placement={placement}>
        {({ ref, style, placement, arrowProps }) =>
          isVisible && (
            <div
              className="popper"
              ref={ref}
              style={style}
              data-placement={placement}
            >
              <div className="content" ref={clickBoundary}>
                {children}
              </div>
              {/*
              <div
                className="arrow"
                ref={arrowProps.ref}
                style={arrowProps.style}
                data-placement={placement}
              />*/}
            </div>
          )
        }
      </Popper>

      <style jsx>{`
        .popper {
          z-index: 2;
        }
        .popper[data-placement*="bottom"] {
          margin-top: 7px;
        }
        .popper[data-placement*="top"] {
          margin-bottom: 7px;
        }
        .popper[data-placement*="right"] {
          margin-left: 7px;
        }
        .popper[data-placement*="left"] {
          margin-right: 7px;
        }
        .content {
          /*border-radius: 0.3em;*/
          background-color: ${css.backgroundColor};
          /*border: 1px solid ${css.borderColor};*/
          box-shadow: 0 0 0.1em 0.05em ${css.shadowColor};
          /*transition: 200ms background-color, 200ms border-color,
            200ms shadow-color;*/
          /*padding: 0.5em;*/
          padding-top: 7px;
          padding-bottom: 7px;
          font-size: 16px;
          font-color: #747474;
        }
        .arrow {
          position: absolute;
          height: 8px;
          width: 8px;
        }
        .arrow::before,
        .arrow::after {
          content: "";
          margin: auto;
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent;
          transition: 200ms border-color;
        }
        .arrow[data-placement*="bottom"] {
          top: 0;
          left: 0;
          margin-top: -5px;
          width: 8px;
          height: 6px;
        }
        .arrow[data-placement*="bottom"]::before {
          border-width: 0 4px 6px 4px;
          border-bottom-color: ${css.borderColor};
        }
        .arrow[data-placement*="bottom"]::after {
          bottom: -1px;
          border-width: 0 4px 6px 4px;
          border-bottom-color: ${css.backgroundColor};
        }
        .arrow[data-placement*="top"] {
          bottom: 0;
          left: 0;
          margin-bottom: -5px;
          width: 8px;
          height: 6px;
        }
        .arrow[data-placement*="top"]::before {
          border-width: 6px 4px 0 4px;
          border-top-color: ${css.borderColor};
        }
        .arrow[data-placement*="top"]::after {
          top: -1px;
          border-width: 6px 4px 0 4px;
          border-top-color: ${css.backgroundColor};
        }
        .arrow[data-placement*="right"] {
          left: 0;
          margin-left: -5px;
          height: 8px;
          width: 6px;
        }
        .arrow[data-placement*="right"]::before {
          border-width: 4px 6px 4px 0;
          border-right-color: ${css.borderColor};
        }
        .arrow[data-placement*="right"]::after {
          right: -1px;
          border-width: 4px 6px 4px 0;
          border-right-color: ${css.backgroundColor};
        }
        .arrow[data-placement*="left"] {
          right: 0;
          margin-right: -5px;
          height: 8px;
          width: 6px;
        }
        .arrow[data-placement*="left"]::before {
          border-width: 4px 0 4px 6px;
          border-left-color: ${css.borderColor};
        }
        .arrow[data-placement*="left"]::after {
          left: -1px;
          border-width: 4px 0 4px 6px;
          border-left-color: ${css.backgroundColor};
        }
      `}</style>
    </Manager>
  )
}

export default Dropdown
