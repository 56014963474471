import { faEnvelope } from "@fortawesome/pro-light-svg-icons"
import { faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { navigate } from "gatsby"
import React, { useCallback, useEffect } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useMenus } from "../../hooks/use-menus"
import { useStyle } from "../../hooks/use-style"
import { useTheme } from "../../hooks/use-theme"
import { useUser } from "../../hooks/use-user"
import { prettyBalance } from "../../services/number"
import { darkTheme, lightTheme } from "../../services/theme"
import Avatar from "../layout/avatar"
import Dropdown from "../layout/dropdown"

const UserPreview = () => {
  const { user, isLoggedIn, logout } = useUser()
  const { filters, updateFilters } = useFilters()

  const [menu, deactivate] = useMenus(["user"])
  const toggle = useCallback(() => {
    if (menu.user.isActive) deactivate()
    else menu.user.setActive()
  }, [menu.user.isActive])

  useEffect(() => {
    if (user) {
      deactivate()
    }
  }, [user])

  const css = useStyle(theme => ({
    avatarColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    avatarBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    switchColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    switchHandleColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    switchOutlineColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  return (
    <article>
      <Dropdown
        visible={menu.user.isActive}
        onClickOutside={deactivate}
        placement="bottom-end"
        element={({ ref }) => (
          <div className="user" ref={ref} onClick={toggle}>
            {user && user.post > user.post_read && (
              <div
                className="post"
                onClick={e => {
                  e.stopPropagation()
                  updateFilters({ donation_feed: "Support" })
                  navigate("/donate")
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
              </div>
            )}

            <div className="avatar">
              {isLoggedIn() &&
              user &&
              user.profile.image &&
              user.profile.image.length != 0 ? (
                <Avatar
                  image={user.profile.image}
                  label={user.profile.title}
                  size={36}
                />
              ) : (
                <FontAwesomeIcon icon={faUser} size="xs" />
              )}
            </div>

            <div className="user-data">
              {isLoggedIn() &&
                (user?.profile.title && user?.profile.title.length != 0 ? (
                  <div>{user.profile.title}</div>
                ) : (
                  <div style={{ whiteSpace: "nowrap" }}>
                    {user ? user.id : ""}
                  </div>
                ))}
              {isLoggedIn() &&
                user &&
                user.balance &&
                user.balance.currency_symbol && (
                  <div style={{ fontSize: "12px", color: "rgb(96, 96, 96)" }}>
                    {user?.balance.balance < 0 ? "-" : ""}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: user?.balance.currency_symbol,
                      }}
                    />
                    {prettyBalance(Math.abs(user?.balance.balance))}
                  </div>
                )}
            </div>
          </div>
        )}
      >
        <ul>
          {isLoggedIn() == false && (
            <li>
              <div
                className="menuItem"
                onClick={() => {
                  navigate("/login", { state: { modal: true } })
                  deactivate()
                }}
              >
                Login
              </div>
            </li>
          )}
          {isLoggedIn() && (
            <li>
              <div
                className="menuItem"
                onClick={() => {
                  logout()
                  navigate("/login", { state: { modal: true } })
                  deactivate()
                }}
              >
                Log out
              </div>
            </li>
          )}
          <li>
            <div
              className="menuItem"
              onClick={() => {
                navigate("/settings")
                deactivate()
              }}
            >
              Settings
            </div>
          </li>
        </ul>
      </Dropdown>

      <style jsx>{`
                .post {
                  margin-right: 5px;
                  color: rgb(129, 129, 129);
                  z-index: 100;
                  
                }
                .post:hover {
                  cursor: pointer;
                  color: blue;
                }

                .panel {
                  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19);
                  border-radius: 5px;
                  background-color: white;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  z-index: 1000;

                }


                .menuItem {
                    padding: 7px;
                    font-size: 14px;
                    font-family: 'Roboto';
                    padding-left: 20px;
                    width: 100%;
                }
                .menuItem:hover {
                    cursor: pointer;
                    background-color: #eee;
                }
                article {
                    max-width: 100%;
                    z-index: 1000;
                }
                .user {
                    display: flex;
                    flex-basis: 0;
                    align-items: center;
                    padding: 0.5em 1em 0.5em 0.5em;
                    border-radius: 0.3em;
                    font-size: 15px;
                }
                .user:hover {
                    cursor: pointer;
                }
                .user p {
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .user-data {
                    font-size: 13px;
                    font-family: Roboto;
                    /*overflow: hidden;*/
                    margin-left: 0.5em;
                    color: rgb(3, 3, 3);
                }
                .avatar {
                    min-width: 40px;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: ${css.avatarBackgroundColor};
                    color: ${css.avatarColor};
                    transition: 200ms background-color, 200ms color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid ${css.borderColor};
                }
                .icon {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                ul {
                    width: 200px;
                    /*
                    list-style: none;
                    padding: 0 0.5em;
                    margin: 0;*/
                    padding: 0;
                    margin: 0;
                }
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    /*padding: 0.5em 0;*/
                }
                /*li:not(:first-child) {
                    border-top: 1px solid ${css.borderColor};
                    transition: 200ms border-color;
                }*/
                .label {
                    margin-right: 1em;
                }
            `}</style>
    </article>
  )
}

export default UserPreview
