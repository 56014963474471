export const prettyAmount = (a: number) => {
  let abs = Math.abs(a)
  //let a = n / 100
  if (abs < 0.001) {
    // tiny number - display the most significant digit only
    return a.toFixed(1)
  } else if (abs < 0.01) return a.toFixed(3)
  else if (abs < 100) return a.toFixed(2)
  else if (abs < 1000) return Math.floor(a)
  else if (abs < 100000) return (a / 1000).toFixed(1) + "K"
  else if (abs < 1000000000) return (a / 1000000).toFixed(1) + "M"
  else return (a / 1000000000).toFixed(1) + "B"
}

export const prettyBalance = (a: number) => {
  if (a < 0.001) return a
  else if (a < 1000) return a.toFixed(2)
  else if (a < 100000) return (a / 1000).toFixed(1) + "K"
  else if (a < 1000000000) return (a / 1000000).toFixed(1) + "M"
  else return (a / 1000000000).toFixed(1) + "B"
}

export const prettyCount = (a: number) => {
  if (a < 10) return a
  //a.toFixed(2)
  else if (a < 1000) return Math.floor(a)
  else if (a < 100000) return (a / 1000).toFixed(1) + "K"
  else if (a < 1000000000) return (a / 1000000).toFixed(1) + "M"
  else return (a / 1000000000).toFixed(1) + "B"
}
