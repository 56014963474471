import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useMedia } from "../../hooks/use-media"
import { useStyle } from "../../hooks/use-style"
import { darkTheme, lightTheme } from "../../services/theme"

type Props = {
  id: string
  submit: string
  compact: boolean | undefined
  children?: React.ReactNode
}

const Notice: FunctionComponent<Props> = (props: Props) => {
  const [show, setShow] = useState(false)
  const { numColumns } = useMedia()

  const css = useStyle(theme => ({
    textColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    activeTextColor:
      theme === "light"
        ? lightTheme.color.text.primary
        : darkTheme.color.text.primary,
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  useEffect(() => {
    if (window) {
      const v = localStorage.getItem(props.id)
      if (v == null) setShow(true)
      else setShow(false)
    }
  }, [props.id])

  const consent = useCallback(() => {
    if (window) {
      setShow(false)
      localStorage.setItem(props.id, "seen")
    }
  }, [props.id])

  return (
    <>
      {show && (
        <>
          {numColumns == 1 ? (
            <div className="notice">
              <div className={props.compact ? "container" : "m-container"}>
                <div className={props.compact ? "children" : "m-children"}>
                  {props.children}
                </div>
                <div className={props.compact ? "got-it" : "m-got-it"}>
                  <button onClick={consent}>{props.submit}</button>
                </div>
              </div>
              <style jsx>{`
                .container {
                  display: table-row;
                }
                .m-container {
                }
                .notice {
                  width: 100%;
                  padding: 5px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  background-color: wheat;
                  margin-bottom: 5px;
                  border-radius: 0.3em;
                  font-family: "Roboto";
                  display: table;
                }
                .children {
                  display: table-cell;
                  vertical-align: top;
                  text-align: justify;
                }
                .m-children {
                  display: table-cell;
                  vertical-align: top;
                  text-align: justify;
                }
                .got-it {
                  display: table-cell;
                  text-align: right;
                }
                .m-got-it {
                  text-align: right;
                }
                button {
                  border: none;
                  border-radius: 0.3em;
                  transition: 200ms background-color, 200ms color;
                  padding: 0.5em 1em;
                  color: #444;
                  min-width: 50px;
                }
                button:hover {
                }
              `}</style>
            </div>
          ) : (
            <div className="notice">
              <div style={{ display: "table-row" }}>
                <div style={{ display: "table-cell" }}>
                  <div>
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  </div>
                </div>
                <div className="children">
                  {props.children}
                  <div></div>
                </div>
              </div>
              <div style={{ display: "table-row" }}>
                <div style={{ display: "table-cell" }}></div>
                <div style={{ display: "table-cell", textAlign: "right" }}>
                  <button onClick={consent}>{props.submit}</button>
                </div>
              </div>
              <style jsx>{`
                .notice {
                  width: 100%;
                  padding: 40px 40px 15px 15px;
                  background-color: wheat;
                  margin-bottom: 20px;
                  border-radius: 0.3em;
                  font-family: "Roboto";
                  display: table;
                }
                .children {
                  display: table-cell;
                  vertical-align: top;
                  padding-left: 15px;
                  text-align: justify;
                }
                button {
                  border: none;
                  border-radius: 0.3em;
                  transition: 200ms background-color, 200ms color;
                  padding: 0.5em 1em;
                  color: #444;
                  min-width: 7.5em;
                }
                button:hover {
                }
              `}</style>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Notice
