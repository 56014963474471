import { FunctionComponent, useEffect, useState } from "react"
import {
  faHome,
  faList,
  faBookmark,
  faUser,
  faHeart,
  faMicrophone,
  faPhotoVideo,
  faUsers,
  faNewspaper,
  faBrowser,
  faComment,
  faChartLine,
} from "@fortawesome/pro-regular-svg-icons"
import WidgetNavbar from "."
import { useUser } from "../../../hooks/use-user"
import React from "react"

const TopLinks: FunctionComponent = () => {
  const { user } = useUser()

  const [items, setItems] = useState({
    feed: {
      label: "Charts",
      to: "/",
      icon: faChartLine,
    },
    authors: {
      label: "Authors",
      to: "/authors",
      icon: faUsers,
    },
    websites: {
      label: "Websites",
      to: "/websites",
      icon: faBrowser,
    },
  })

  useEffect(() => {
    //console.log("moderation_feed changed " + user?.state)
    if (user && user?.state >= 10) {
      setItems({
        feed: {
          label: "Charts",
          to: "/",
          icon: faChartLine,
        },
        authors: {
          label: "Authors",
          to: "/authors",
          icon: faUsers,
        },
        websites: {
          label: "Websites",
          to: "/websites",
          icon: faBrowser,
        },
        comments: {
          label: "Comments",
          to: "/allcomments",
          icon: faComment,
        },
      })
    } else {
      setItems({
        feed: {
          label: "Charts",
          to: "/",
          icon: faChartLine,
        },
        authors: {
          label: "Authors",
          to: "/authors",
          icon: faUsers,
        },
        websites: {
          label: "Websites",
          to: "/websites",
          icon: faBrowser,
        },
      })
    }
  }, [user?.state])

  return <WidgetNavbar items={items} />
}

export default TopLinks
