import { useEffect, RefObject } from "react"

export const useSticky = (
    stickyElement: RefObject<HTMLElement>,
    baseOffset = 0
) => {
    useEffect(() => {
        let lastScrollY = window.scrollY
        let lastOffset = 0

        const updateLayout = () => {
            if (stickyElement.current) {
                const { style, scrollHeight } = stickyElement.current

                if (scrollHeight > window.innerHeight) {
                    const diff = window.innerHeight - scrollHeight
                    const delta = window.scrollY - lastScrollY
                    const offset = Math.min(
                        baseOffset,
                        Math.max(diff, lastOffset - delta)
                    )

                    style.top = offset + "px"
                    lastOffset = offset
                } else {
                    style.top = baseOffset + "px"
                }
            }

            lastScrollY = window.scrollY
        }

        let animationFrameRequestId: number
        const eventListener = () => {
            if (animationFrameRequestId) {
                window.cancelAnimationFrame(animationFrameRequestId)
            }
            animationFrameRequestId = window.requestAnimationFrame(updateLayout)
        }

        window.addEventListener("scroll", eventListener)
        window.addEventListener("resize", eventListener)

        return () => {
            window.removeEventListener("scroll", eventListener)
            window.removeEventListener("resize", eventListener)
        }
    }, [baseOffset])
}
