import { FunctionComponent } from "react"
import WidgetList from "../../layout/widget/list"
import { lightTheme, darkTheme } from "../../../services/theme"
import { useStyle } from "../../../hooks/use-style"
import { Props as WidgetListProps } from "../../layout/widget/list"
import React from "react"

type Props = {
    items: WidgetListProps["items"]
}

const WidgetNavbar: FunctionComponent<Props> = ({ items }) => {
    const css = useStyle(theme => ({
        backgroundColor:
            theme === "light"
                ? lightTheme.color.background.secondary
                : darkTheme.color.background.primary,
        borderColor:
            theme === "light"
                ? lightTheme.color.border
                : darkTheme.color.border,
    }))

    return (
        <WidgetList
            borderless
            activeStyle={{
                backgroundColor: css.backgroundColor,
                marginRight: "-1px",
                borderTop: "1px solid " + css.borderColor,
                borderBottom: "1px solid " + css.borderColor,
                borderLeft: "1px solid " + css.borderColor,
                borderRight: "1px solid " + css.backgroundColor,
            }}
            items={items}
        />
    )
}

export default WidgetNavbar
