import { FunctionComponent, createElement } from "react"
import { useStyle } from "../hooks/use-style"
import React from "react"
import { lightTheme, darkTheme } from "../services/theme"

type Props = {
  image: string
  creator: string
  text: string
}

const CustomNotification: FunctionComponent<Props> = ({
  image,
  creator,
  text,
}) => {
  const makeNick = () => {
    let p = creator.split(" ")
    if (p.length > 1) return (p[0][0] + p[1][0]).toUpperCase()
    else return creator.substr(0, 2).toUpperCase()
  }

  const nick = image && image.length > 0 ? "" : creator ? makeNick() : ""

  return (
    <div className="toast">
      <div className="img-container">
        {image ? <img src={image} alt={creator} /> : nick}
      </div>
      <div className="message">
        <div className="creator">{creator}</div>
        <div className="text">{text}</div>
      </div>

      <style jsx>{`
        .img-container {
          margin-right: 8px;
          height: 50px;
          width: 50px;
          font-size: 25px;
          flex-shrink: 0;
          background-image: ${image ? `url("${image}")` : "none"};
          transition: 200ms background-color, 200ms color, 200ms border-color;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          position: absolute;
          top: -9999px;
          left: -9999px;
          right: -9999px;
          bottom: -9999px;
          margin: auto;
          opacity: 0;
        }
        .toast {
          width: 350px;
          min-width: 0px;
          height: 100px;
          padding: 10px;
          background-color: #333333;
          display: table;
          overflow: hidden;
        }
        .message {
          display: table-cell;
          vertical-align: top;
          overflow: hidden;
          min-width: 0px;
        }
        .creator {
          color: #ffffff;
          font-weight: 400;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0px;
          white-space: nowrap;
          flex: 1 1 0%;
          width: 260px;
        }
        .text {
          color: #bbbbbb;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-width: 260px;
        }
      `}</style>
    </div>
  )
}

export default CustomNotification
