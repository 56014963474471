import { FunctionComponent, useMemo } from "react"
import { lightTheme, darkTheme } from "../../services/theme"
import { useStyle } from "../../hooks/use-style"
import React from "react"

type Props = {
  image?: string
  label: string
  size: number
}

const Avatar: FunctionComponent<Props> = ({ image, label, size }) => {
  const css = useStyle(theme => ({
    color:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const makeNick = () => {
    let p = label.split(" ")
    if (p.length > 1) return (p[0][0] + p[1][0]).toUpperCase()
    else return label.substr(0, 2).toUpperCase()
  }

  const nick = image && image.length > 0 ? "" : label ? makeNick() : ""

  return (
    <div>
      {image ? <img src={image} alt={label} /> : nick}
      <style jsx>{`
        div {
          height: ${size}px;
          width: ${size}px;
          flex-shrink: 0;
          background-image: ${image ? `url("${image}")` : "none"};
          color: ${css.color};
          background-color: ${css.backgroundColor};
          transition: 200ms background-color, 200ms color, 200ms border-color;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 50%;
          border: 1px solid ${css.borderColor};
          position: relative;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: ${size / 2}px;
        }
        img {
          position: absolute;
          top: -9999px;
          left: -9999px;
          right: -9999px;
          bottom: -9999px;
          margin: auto;
          opacity: 0;
        }
      `}</style>
    </div>
  )
}

export default Avatar
