import { FunctionComponent, useState, useCallback, useMemo } from "react"
import { useUser } from "../../../hooks/use-user"
import WidgetNavbar from "."
import { faChevronUp, faChevronDown } from "@fortawesome/pro-light-svg-icons"
import React from "react"
import { faLoveseat, faList } from "@fortawesome/pro-solid-svg-icons"

const Subscriptions: FunctionComponent = () => {
  const { user, showMoreSubscriptions, setShowMoreSubscriptions } = useUser()

  if (!user) {
    //console.log("Subscriptions: user not set")
    //return <WidgetNavbar items={{}} />
  } else {
    //console.log("Subscriptions: user set")
  }

  const maxItems = 10
  const isExpandable = user?.subscriptions.length > maxItems
  const subscriptions = useMemo(
    () =>
      isExpandable && !showMoreSubscriptions && user
        ? user.subscriptions.slice(0, maxItems)
        : user
        ? user.subscriptions
        : [],
    [isExpandable, showMoreSubscriptions, user?.subscriptions]
  )
  const toggleExpanded = useCallback(() => {
    setShowMoreSubscriptions(!showMoreSubscriptions)
  }, [showMoreSubscriptions])

  return (
    <WidgetNavbar
      items={{
        subscriptions: {
          label: "Subscriptions",
          to: "/subscriptions",
          icon: faList,
        },
        ...subscriptions.reduce(
          (items, subscription) => ({
            ...items,
            [subscription.id]: {
              label: subscription.title,
              image: subscription.icon,
              to: "/channel/" + subscription.id,
              dot:
                subscription.recent_content !== null &&
                subscription.last_viewed !== null
                  ? subscription.recent_content > subscription.last_viewed
                  : false,
              star: subscription.regularSupport ? true : false,
            },
          }),
          {}
        ),
        ...(isExpandable && {
          ["expanded" + subscriptions.length]: {
            label: showMoreSubscriptions
              ? "Show less"
              : `Show ${user.subscriptions.length - maxItems} more`,
            icon: showMoreSubscriptions ? faChevronUp : faChevronDown,
            to: toggleExpanded,
          },
        }),
      }}
    />
  )
}

export default Subscriptions
