import {
  faCog,
  faUsersCog,
  faMicrophoneSlash,
} from "@fortawesome/pro-light-svg-icons"
import React, { FunctionComponent, useEffect, useState } from "react"
import WidgetNavbar from "."
import { useUser } from "../../../hooks/use-user"

const Etc: FunctionComponent = () => {
  const { user } = useUser()
  const [items, setItems] = useState({
    muted: {
      label: "Muted",
      to: "/muted",
      icon: faMicrophoneSlash,
    },
    settings: {
      label: "Settings",
      to: "/settings",
      icon: faCog,
    },
  })

  useEffect(() => {
    //console.log("moderation_feed changed " + user?.state)
    if (user && user?.state >= 10) {
      setItems({
        muted: {
          label: "Muted",
          to: "/muted",
          icon: faMicrophoneSlash,
        },
        settings: {
          label: "Settings",
          to: "/settings",
          icon: faCog,
        },
        moderation: {
          label: "Moderators",
          to: "/moderators",
          icon: faUsersCog,
        },
      })
    } else {
      setItems({
        muted: {
          label: "Muted",
          to: "/muted",
          icon: faMicrophoneSlash,
        },
        settings: {
          label: "Settings",
          to: "/settings",
          icon: faCog,
        },
      })
    }
  }, [user?.state])

  return <WidgetNavbar items={items} />
}

export default Etc
