import { useCallback, useState } from "react"
import { Item, LikeType } from "../types"
import { useIndexedDB } from "./use-indexeddb"
import { useLike } from "./use-like"
import { useUser } from "./use-user"

export const useItems = (initialItems: Item[]) => {
  const [items, setItems] = useState(initialItems)
  const { user, accountState } = useUser()
  const { sendLike } = useLike()
  const { putKV } = useIndexedDB()

  const updateItem = useCallback(
    (updatedItem: Item) =>
      setItems(items =>
        items.map(item => (item.id === updatedItem.id ? updatedItem : item))
      ),
    []
  )

  const addItems = useCallback((newItems: Item[]) => {
    setItems(items => items.concat(newItems))
  }, [])

  const likeItem = useCallback(
    async (item: Item, amount: number) => {
      /*if (accountState < 0) {
        // local - todo: implement
        return
      }*/

      const msg = {
        kind: LikeType.Item,
        target: item.url,
        amount: amount,
        currency: "USD",
      }

      sendLike(msg, item, null, function () {
        item.amount += amount
        updateItem(item)
      })
    },
    [user, accountState]
  )

  const likeNewItem = useCallback(
    async (url: string, publisher: string, amount: number) => {
      const msg = {
        kind: LikeType.Item,
        target: url,
        amount: amount,
        currency: "USD",
      }

      sendLike(msg, null, null, function () {
        saveLike(url, amount, "USD")
      })
    },
    [user]
  )

  const saveLike = (url: string, amount: number, currency: string) => {
    var parser = document.createElement("a")
    parser.href = url

    let item = {
      publisher: {
        id: parser.hostname,
        title: parser.hostname,
        name: parser.hostname,
        icon: "",
      },
      url: url,
      title: "",
      description: "",
      image: "",
      tags: [],
      added_date: Math.floor(Date.now() / 1000),
      amount: amount,
      reload: true,
    }

    let r = putKV("item_likes", item.url, {
      item: item,
      alias: "",
      domain: item.publisher.id,
      amount: amount,
      tags: item.tags,
      ts: Date.now(),
      search: url,
    })
    if (r) {
      r.onsuccess = function (e) {
        console.log("Added")
      }
      r.onerror = function (e) {
        console.log("error")
      }
    }
  }

  return {
    items,
    setItems,
    addItems,
    updateItem,
    likeItem,
    likeNewItem,
    saveLike,
  }
}
