import { FunctionComponent } from "react"
import {
  faUser,
  faUserFriends,
  faChartNetwork,
  faHeart,
  faDonate,
} from "@fortawesome/pro-regular-svg-icons"
import WidgetNavbar from "."
import { useUser } from "../../../hooks/use-user"
import React from "react"

const MiddleLinks: FunctionComponent = () => {
  const { user } = useUser()

  return (
    <WidgetNavbar
      items={{
        ...(user && {
          donate: {
            label: "Donations",
            to: "/donate",
            icon: faDonate,
          },
          content: {
            label: "My Content" /*user.profile.title*/,
            icon: faUser,
            to: "/channel/" + user.id,
          },
          supporters: {
            label: "My Supporters",
            to: "/supporters",
            icon: faChartNetwork,
          },
        }),
        /*
          ...(user && {
          subscriptions: {
            label: "Subscriptions",
            to: "/subscriptions",
            icon: faList,
          },
        }),
        */
      }}
    />
  )
}

export default MiddleLinks
