import { useState, useCallback, useMemo } from "react"

export const useMenus = <T extends string>(
    menus: T[],
    initial: T | null = null
) => {
    const [menu, setMenu] = useState(initial)

    return [
        useMemo(
            () =>
                menus.reduce(
                    (xs, x) => ({
                        ...xs,
                        [x]: {
                            isActive: menu === x,
                            setActive: () => {
                                if (menu !== x) {
                                    setMenu(x)
                                }
                            }
                        }
                    }),
                    {} as {
                        [key in T]: {
                            isActive: boolean
                            setActive: () => void
                        }
                    }
                ),
            [menu]
        ),
        useCallback(() => setMenu(null), [])
    ] as const
}
