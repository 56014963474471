import { FunctionComponent } from "react"
import { lightTheme, darkTheme } from "../../../services/theme"
import { useStyle } from "../../../hooks/use-style"
import React from "react"

type Props = {
  numComments: number
  title?: string
  borderless?: boolean
  tag?: keyof JSX.IntrinsicElements
}

export const Widget: FunctionComponent<Props> = ({
  numComments,
  title,
  borderless: isBorderless = false,
  tag: Container = "div",
  children,
}) => {
  const css = useStyle(theme => ({
    backgroundColor: isBorderless
      ? "inherit"
      : theme === "light"
      ? lightTheme.color.background.primary
      : darkTheme.color.background.secondary,
    borderColor: isBorderless
      ? "transparent"
      : theme === "light"
      ? lightTheme.color.border
      : darkTheme.color.border,
  }))

  const count = numComments > 0 ? numComments : ""

  return (
    <section style={{ paddingLeft: "5px" }}>
      {title && (
        <h1>
          {count} {title}
        </h1>
      )}

      <Container className="container">{children}</Container>

      <style jsx>{`
        section {
          display: flex;
          flex-direction: column;
        }
        section:not(:first-child) {
          margin-top: 1em;
        }
        section:not(:last-child) {
          margin-bottom: 1.5em;
        }
        h1 {
          font-size: 13px;
          text-transform: uppercase;
          font-weight: normal;
          margin: 0 0 0.5em;
        }
        .container {
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.borderColor};
          border-radius: 0.3em;
          padding: 0.5em ${isBorderless ? 0 : "1em"};
          font-size: 15px;
          flex: 1;
          transition: 200ms border-color, 200ms background-color;
        }
      `}</style>
    </section>
  )
}

export default Widget
