import { FunctionComponent, useCallback } from "react"
import { useLocation, LinkProps } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"
import { useUpdate } from "../hooks/use-update"

//const RouterLinkBoolean : RouterLink<boolean> = props => {}

const Link: FunctionComponent<LinkProps<boolean>> = props => {
  const { setUpdateFlag } = useUpdate()
  //const router = useRouter()
  //const href = props.href

  /*
  if (filterableRoutes.includes(props.to)) {
    const { query } = url.parse(router1.pathname, true)
    let params = qs.stringify(updateQuery(filters, query))
    if (params) {
      params = "?" + params
      props = {
        ...props,
        to: props.to + params,
      }
    }
  }*/

  //const component = React.createElement(RouterLink<boolean>, props, null);

  //    <RouterLink {...props} style={{ textDecoration: "none" }}>

  //return (<RouterLink {...props} style={{ textDecoration: "none" }}>)
  /*
  return (
    <RouterLink {...props} style={{ textDecoration: "none" }}>
      {props.children}
    </RouterLink>
  )
  */
  const { pathname } = useLocation()

  const go = useCallback(() => {
    //navigate(props.to)
    //return
    if (pathname != props.to) {
      navigate(props.to)
    } else {
      //console.log("=============================== " + pathname)
      //navigate("/reload")
      //setTimeout(() => {
      //navigate(props.to, {
      //  state: { ts: new Date().getTime() },
      //  replace: true,
      //})
      //})
      setUpdateFlag(true)
      navigate(props.to)
    }
  }, [props])

  return (
    <div onClick={go} className="link">
      {props.children}

      <style jsx>{`
        .link {
          display: contents;
        }
        .link:hover {
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default Link
