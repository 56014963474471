export enum LikeType {
  Item = 0,
  Channel = 1,
  Author = 2,
  Comment = 3,
  CommentChannel = 4,
  Commentator = 5,
  Donate = 6,
  DonateChannel = 7,
}

export enum MuteType {
  Creator = 0,
  Domain = 1,
  Owner = 2,
  Item = 3,
}

export type SignedMessage = {
  data: string
  sender: string
  signature: string
}

export type Balance = {
  balance: number
  donation_balance: number
  credit_line: number
  currency_symbol: string
}

export type Support = {
  amount_in: number
  amount_out: number
  users_in: number
  users_out: number
}

export type RSAKeys = {
  userid: string
  dm_public_key: string
  dm_encrypted_private_key: string
  dm_iv: string
}

export type User = {
  id: string
  pk: string
  pk_base64: string
  state: number
  account_type: string
  balance: Balance
  classes: number[]
  profile: Channel
  channels: Channel[]
  subscriptions: Channel[]
  bookmarks: Bookmark[]
  displayed_currency: string
  displayed_currency_rate: number
  displayed_currency_symbol: string
  like_value: number
  post: number
  post_read: number
  support: Support
  dm_keys: RSAKeys
}
export type Alias = {
  id: string
  alias: string
  title: string
  image: string
  cut: number
}

export type RegularSupport = {
  amount: number
  period: number
  added: number
  lastTransfer: number
}

export type Channel = {
  id: string
  title: string
  name: string
  description: string | null
  image: string | null
  icon: string | null
  url: string | null
  amount: number
  items_count: number
  gateways: Gateway[]
  notifications: boolean | null
  alias: string | null
  alias_name: string | null
  alias_host: string | null
  owner: string | null
  aliases: Alias[]
  last_viewed: number | null
  num_views: number | null
  recent_content: number | null
  tags: string[] | null
  btags: string[] | undefined
  mf: number | undefined
  mfg: number | undefined
  comments_count: number
  donations: number
  added_date: string | number | null
  publisher: Channel | null
  kind: number
  regularSupport: RegularSupport | null
  older: number | undefined
}

export type Item = {
  id: string
  title: string
  description: string
  url: string
  image: string | null
  amount: number
  score: number
  creator: Channel | null
  publisher: Channel
  published_at: string | number | null
  added_date: string | number | null
  tags: string[]
  btags: string[] | undefined
  mf: number | undefined
  mfg: number | undefined
  comments: Comment[]
  comments_count: number
  donations: number
  is_nsfw: boolean
  language: string
  country: string
  owner: string | null
  older: number | undefined
}

export type Gateway = {
  id: string
  title: string
  name: string
  description: string | null
  image: string | null
  icon: string | null
  url: string
  gateway_description: string
  amount: number
  address: string
  country: string
  currency: string
  processor: string
  alias: string | null
  alias_name: string | null
  alias_host: string | null
  owner: string | null
  tags: string[]
  added_date: number
  comments_count: number
}
/*
export type Donation = {
  counterparty: string
  channel_id: string
  amount: number
  currency: string
  state: number
  ts: number
  title: string
  icon: string
  handle: string
  publisher: string
  publisher_icon: string
  cut: number
}*/

export type Donation = {
  ts: number
  state: number
  meta: DonationMeta
}

export type Supporter = {
  id: string
  channel: string
  donor: string
  title: string
  avatar: string
  comment: string
  usd_amount: number
  amount: number
  ts: number
}

export type DonationAddress = {
  address: string
  processor: string
  currency: string
  min_amount: number // todo: remove
  max_amount: number
  description: string
  country: string
  gateway: string
  kind: string
  timestamp: number
}

export type GatewayInfo = {
  channel: Channel
  num_users: number
}

export type BaseProfile = {
  account: string
  name: string
  handle: string
  image: string
}

export type DirectOutMessageMeta = {
  from: BaseProfile
  to: BaseProfile
  text: string
  text_outbox: string
}

export type DirectOutMessage = {
  from: string
  to: string
  text: string
  text_outbox: string
}

export type DirectMessage = {
  from: BaseProfile
  to: BaseProfile
  text: string
  encrypted: string
  new: boolean
  ts: number
  id: string
}

export type DonationMeta = {
  amount: number
  currency: string

  title: string
  handle: string
  account: string

  icon: string | null
}

export type Hits = {
  hits: Item[]
}

export type SearchResult = {
  hits: Hits
}

export type Comment = {
  id: string
  target: string
  dht_key: string
  text: string
  amount: number
  author: Channel
  published_at: string
  comments: Comment[]
  comments_count: number
  score: number
  edited: string
  read: number | undefined
}

export type CommentTarget = {
  id: string
  url: string | undefined
  count: number
  kind: LikeType
  owned: boolean | undefined
}

export type Tag = {
  id: string
  rating: number
  vote: boolean | null
}

export type Transaction = {
  kind: string
  /*anchor: string*/
  ts: number
  data: string
}

export type SignedTransaction = {
  tx: Transaction
  sender: string
  signature: string
}

export type Claim = {
  alias: string
  proof: string
  cut: number
  /*control_author_signature: boolean*/
}

export type Bookmark = {
  id: number
  tags: string[]
  numViews: number
  lastViewed: number
}

export type Moderator = {
  id: string
  title: string
  description: string
  amount: number
  ts: number
}
