import { FunctionComponent } from "react"
import { IconLookup } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Avatar from "../avatar"
import React from "react"
import { faStar } from "@fortawesome/pro-light-svg-icons"
//import { RiHome4Line } from "react-icons/ri"

export type Props = {
  label: string
  active?: boolean
  image?: string | null
  icon?: IconLookup
  dot?: boolean
  star?: boolean
}

const WidgetItem: FunctionComponent<Props> = ({
  label,
  active: isActive = false,
  image,
  icon,
  children,
  dot: isDottet = false,
  star: isStarred = false,
}) => {
  return (
    <div className="content">
      {isStarred && (
        <div className="star">
          <FontAwesomeIcon icon={faStar} fixedWidth size="xs" />
        </div>
      )}
      <div className="content item entry">
        {icon ? (
          <div className="icon">
            <FontAwesomeIcon icon={icon} fixedWidth />
          </div>
        ) : (
          typeof image !== "undefined" && (
            <Avatar image={image || undefined} label={label} size={30} />
          )
        )}
        <div className="title" title={label}>
          {label}
        </div>
        {isDottet && (
          <div style={{ display: "table-cell" }}>
            <div className="dot"></div>
          </div>
        )}
      </div>

      {children && <div>{children}</div>}

      <style jsx>{`
        .entry {
          width: 170px;
          display: table;
        }
        .star {
          position: absolute;
          z-index: 100;
          margin-top: -30px;
          margin-left: -10px;
        }
        .dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: grey;
          margin-left: 2px;
        }

        .content {
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 2em;
          min-width: 0;
        }
        .content:not(:last-child) {
          margin-right: 0.5em;
        }
        .icon {
          border-radius: 50%;
          height: 2em;
          width: 2em;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .title {
          display: table-cell;
          flex: 1;
          overflow: hidden; /* TODO: max width */
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0.5em 0;
          margin-left: 0.5em;
          text-align: left;
        }
      `}</style>
    </div>
  )
}

export default WidgetItem
