import { FunctionComponent, createElement } from "react"
import { useStyle } from "../hooks/use-style"
import React from "react"
import { lightTheme, darkTheme } from "../services/theme"

type Props = {
  image: string
  creator: string | null
  text: string
}

const Notification: FunctionComponent<Props> = ({ image, creator, text }) => {
  return (
    <div className="toast">
      <div className="img-container">
        <img src={image} className="img" />
      </div>
      <div className="message">
        <div className="creator">{creator}</div>
        <div className="text">{text}</div>
      </div>

      <style jsx>{`
        .toast {
          width: 350px;
          height: 100px;
          padding: 10px;
          background-color: #333333;
          display: table;
        }
        .img-container {
          display: table-cell;
          height: 100%;
          width: 60px;
          vertical-align: middle;
          margin: auto;
        }
        .img {
          height: 50px;
          width: 50px;
        }
        .message {
          display: table-cell;
          vertical-align: top;
        }
        .creator {
          color: #ffffff;
          font-weight: 400;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0px;
          white-space: nowrap;
          flex: 1 1 0%;
          width: 260px;
        }
        .text {
          color: #bbbbbb;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-width: 260px;
        }
      `}</style>
    </div>
  )
}

export default Notification
