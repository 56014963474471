import { useCallback } from "react"
import { useLocation, LinkProps } from "@reach/router"

export const usePathChecker = (checkRoot = false) => {
  const { pathname } = useLocation()
  const isPath = useCallback(
    (link: string | LinkProps<boolean>) => {
      const basePath = pathname.split("?")[0]
      const url =
        typeof link === "string"
          ? link
          : typeof link.to === "string"
          ? link.to
          : link.to
      /*          ? link.to.pathname
          : typeof link.ref === "string"
          ? link.ref
          : link.ref.pathname*/

      if (basePath === "/") {
        checkRoot = true
      }
      /*console.log(
        "^^^^^ '" +
          basePath +
          "'  '" +
          link +
          "' " +
          (!!url && (checkRoot || url !== "/") && basePath.startsWith(url))
      )*/
      return !!url && (checkRoot || url !== "/") && basePath.startsWith(url)
    },
    [pathname]
  )

  return isPath
}
